import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {fetchMediaList} from "../../../redux/actions/PagesActions";
import Loading from "../../../Components/loading";
import {
    faArrowLeft,
    faFolder,
    faFolderPlus,
    faImage,
    faImages,
    faSearch,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./MediaPage.css";
import ImagesUploadComponent from "../../../Components/ImagesUploadComponent";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {getBaseUrl} from "../../../App";
import {showAlert} from "../../../utils/providers/AlertProvider";

const MediaPage = () => {
    const {mediaList, success, loading} = useSelector((state) => state.mediaListReducer);
    const {directories, files, relative_path, current_directory} = mediaList;
    const dispatch = useDispatch();
    const location = useLocation();
    const [showAddFolder, setShowAddFolder] = useState(false)
    const [folderName, setFolderName] = useState('');
    const [images, setImages] = useState([])
    const [showAddImages, setShowAddImages] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [filteredImages, setFilteredImages] = useState([])
    const [filteredFolders, setFilteredFolders] = useState([])

    useEffect(() => {
        dispatch(fetchMediaList());
    }, [location]);

    useEffect(() => {
        if (success) {
            setFilteredFolders(directories)
            setFilteredImages(files)
        }
    }, [success]);
    const folderClicked = (folder) => {
        dispatch(fetchMediaList(folder.path));
    }

    const goBack = () => {
        const splitParts = relative_path.split("/");
        const result = splitParts.slice(1).join("/").replace(current_directory, '').replace(/(^\/+|\/+$)/g, '');
        dispatch(fetchMediaList(result));
    }

    const handleSearch = () => {
        // Implement search functionality
        setFilteredImages(files.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase())))
        setFilteredFolders(directories.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase())))
    }
    const handleCreateFolder = async () => {
        // Implement create folder functionality
        const splitParts = relative_path.split("/");
        const result = splitParts.slice(1).join("/")
        try {
            const response = await axios.post('/admin/content/media/create-folder', {
                path: result, folder_name: folderName
            });
            showAlert("success", "Folder Added Successfully")
            dispatch(fetchMediaList(result))
        } catch (error) {
            showAlert("error", error.response.data.detail || error.message)
        }
        setShowAddFolder(false);
    };
    const handleUploadImages = async () => {
        if (images.length > 0) {
            const splitParts = relative_path.split("/");
            const result = splitParts.slice(1).join("/")

            const formData = new FormData();
            images.forEach((image) => {
                formData.append('images', image);
            });
            formData.append('path', result);
            try {
                const response = await axios.post('/admin/content/media/upload-images', formData);
                showAlert("success", "Images Uploaded Successfully")
                dispatch(fetchMediaList(result))
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
            setShowAddImages(false);
            setImages([])
        } else {
            showAlert('error', 'Please Select Images')
        }
    };

    return (<React.Fragment>
        {success && (<div className="bg-gradient-to-r from-blue-500 to-green-300 rounded-lg m-10">
            {showAddFolder && (<div className="modal-overlay">
                <div className="modal">
                    <input
                        type="text"
                        placeholder="Folder Name"
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                        className="modal-input"
                    />
                    <button onClick={handleCreateFolder}
                            className="bg-gray-100 flex items-center justify-center gap-2 text-yellow-500 border-[1px] hover:bg-yellow-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                        <FontAwesomeIcon icon={faFolderPlus}/>
                        <span>Create Folder</span>
                    </button>
                    <button onClick={() => setShowAddFolder(false)}
                            className="bg-gray-100 flex items-center justify-center gap-2 text-red-500 border-[1px] hover:bg-red-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                        <FontAwesomeIcon icon={faTimes}/>
                        <span>Close</span>
                    </button>
                </div>
            </div>)}
            {showAddImages && (<div className="modal-overlay overflow-scroll">
                <div className="modal w-[50%]">
                    <ImagesUploadComponent media={true} title={"Upload Image/Images"} buttonText={"Select Image/Images"}
                                           setImages={setImages} images={images}/>
                    <button onClick={handleUploadImages}
                            className="bg-gray-50 flex items-center justify-center gap-2 text-blue-500 border-[1px] hover:bg-blue-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                        <FontAwesomeIcon icon={faImages}/>
                        <span>Upload Images</span>
                    </button>
                    <button onClick={() => setShowAddImages(false)}
                            className="bg-gray-50 flex items-center justify-center gap-2 text-red-500 border-[1px] hover:bg-red-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                        <FontAwesomeIcon icon={faTimes}/>
                        <span>Close</span>
                    </button>
                </div>
            </div>)}
            <div className="path-header flex justify-between px-5">
                <div className="grid grid-cols-1 lg:flex lg:grid-cols-3 gap-2">
                    <button
                        className="bg-white text-black rounded-full px-3 h-9 text-sm hover:bg-black hover:text-white  transition-all"
                        onClick={goBack}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <button
                        className="bg-white text-blue-500 flex items-center gap-2 hover:bg-blue-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all"
                        onClick={() => setShowAddImages(true)}>
                        <FontAwesomeIcon icon={faImages}/>
                        <span>Upload Images</span>
                    </button>
                    <button
                        className="bg-white text-yellow-500 flex items-center gap-2 hover:bg-yellow-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all"
                        onClick={() => setShowAddFolder(true)}>
                        <FontAwesomeIcon icon={faFolderPlus}/>
                        <span>Add Folder</span>
                    </button>
                </div>
                <div
                    className={`flex mb-2 mx-1 max-w-[50%] justify-center items-center transition-all duration-500`}>
                    <input type="text" id="searchBar" value={searchValue}
                           onChange={(e) => setSearchValue(e.target.value)}
                           className="py-2 px-3 text-md rounded-bl-md rounded-tl-md w-full text-black outline-none border border-gray-200"
                           placeholder="Search"/>
                    <button onClick={handleSearch}
                            className="text-md bg-white rounded-br-md rounded-tr-md -ml-0.5 cursor-pointer border border-gray-200 font-light hover:font-normal py-2 px-4 text-gray-600">
                        <FontAwesomeIcon icon={faSearch}/></button>
                </div>
            </div>
            <div className="path-header">{relative_path}</div>
            <section className="media-container">
                {filteredFolders.map((directory, index) => (
                    <div onClick={() => folderClicked(directory)} key={index} className="media-item">
                        <FontAwesomeIcon icon={faFolder} className="media-icon folder"/>
                        <span className="media-name">{directory.name}</span>
                    </div>))}
                {filteredImages.map((file, index) => (
                    <a target="_blank" href={getBaseUrl() + "/media/" + relative_path + "/" + file.name}>
                        <div key={index} className="media-item">
                            <FontAwesomeIcon icon={faImage} className="media-icon file"/>
                            <span className="media-name">{file.name}</span>
                        </div>
                    </a>))}
                {directories.length === 0 && files.length === 0 ?
                    <div className='h-[50vh] flex items-center justify-center'>
                        <span>This Folder is Empty!</span>
                    </div> : filteredFolders.length === 0 && filteredImages.length === 0 &&
                    <div className='h-[50vh] flex items-center justify-center'>
                        <span>No search result was found!</span>
                    </div>}
            </section>
        </div>)}
        {loading && <Loading/>}
    </React.Fragment>)
}

export default MediaPage;
