import React, {useEffect} from "react";
import csv from "csvtojson"
import * as XLSX from 'xlsx';
import axios from "axios";
import Select from 'react-select';
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import {fetchVariations, fetchVariationTypes} from "../../../redux/actions/VariationActions";
import {getBaseUrl} from "../../../App";
import {fetchCollectionList, fetchCategoryList} from "../../../redux/actions/CategoryActions";

const UploadBulkProductPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchVariations())
        dispatch(fetchVariationTypes())
        dispatch(fetchCollectionList())
        dispatch(fetchCategoryList())
    }, [])
    const {variations} = useSelector((state) => state.variationsList);
    const {variationTypes} = useSelector((state) => state.variationTypesList);
    const {categories} = useSelector((state) => state.categoryList);
    const {collections} = useSelector((state) => state.collectionList);
    const [showAlert, AlertComponent] = useAlert();
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.name.slice(-5) === ".xlsx") {
                xlsxFileRead(file)
            } else if (file.name.slice(-4) === ".csv") {
                csvFileRead(file)
            } else {
                console.log("File Type not Supported")
            }
        }
    }
    const uploadData = async (products) => {
        try {
            const {data} = await axios.post('/admin/products/update/bulk', products);
            showAlert("success", "Products Uploaded Successfully")
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    const csvFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const csvData = e.target.result;
            const jsonData = await csv().fromString(csvData);
            uploadData(jsonData)
            // Perform further operations with the converted JSON data
        };
        reader.readAsText(file);
    }
    const xlsxFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});

            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one to convert
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            uploadData(jsonData)
        }
        reader.readAsArrayBuffer(file);
        // Perform further operations with the converted JSON data
    }
    const Option = ({innerProps, data}) => (
        <div {...innerProps} className="flex justify-between items-center cursor-pointer hover:bg-gray-200">
            <span className="ml-2">{data.name}</span>
            <span className="mr-2">{data.id}</span>
        </div>
    );

    return (
        <React.Fragment>
            {AlertComponent}
            <div className="bg-gray-100 py-1">
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Upload Bulk Product</div>
                    <hr/>
                    <div>
                        <label htmlFor="file_uploader"
                               className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                            <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                            <span className="text-lg text-gray-600">Supported format: csv, xlsx</span>
                            <span
                                className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">Browse File</span>
                        </label>
                        <input id="file_uploader" type="file" accept=".csv, .xlsx" className="hidden"
                               onChange={handleFileUpload}/>
                    </div>
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Documents For Help</div>
                    <hr/>
                    These documents can be used to generate your own csv file for quick upload
                   <a target="_blank" href={getBaseUrl()+"/admin/content/file/sample-products.xlsx"}> <div className="rounded bg-blue-600 text-white py-2 flex justify-center">
                        Download Sample XLSX
                    </div></a>
                    <a target="_blank" href={getBaseUrl()+"/admin/content/file/template-products.xlsx"}> <div className="rounded bg-green-600 text-white py-2 flex justify-center">
                        Download Template XLSX
                    </div></a>

                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Category Id Finder</div>
                    <hr/>
                    You can use this to find the id of categories for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...categories.map(x => ({
                                value: x.name+"  id="+x.id, label: x.name+"  id="+x.id, name:x.name, id:x.id
                            }))]}
                            placeholder={'Categories'}
                            components={{
                                Option,
                            }}  />
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Collection Id Finder</div>
                    <hr/>
                    You can use this to find the id of collections for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...collections.map(x => ({
                                value: x.name+"  id="+x.id, label: x.name+"  id="+x.id, name:x.name, id:x.id
                            }))]}
                            placeholder={'Collections'}
                            components={{
                                Option,
                            }}  />
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Variation Types Id Finder</div>
                    <hr/>
                    You can use this to find the id of variation types for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...variationTypes.map(x => ({
                                value: x.variation_type+"  id="+x.id, label: x.variation_type+"  id="+x.id, name:x.variation_type, id:x.id
                            }))]}
                            placeholder={'Variation Types'}
                            components={{
                                Option,
                            }}  />
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Variation Id Finder</div>
                    <hr/>
                    You can use this to find the id of variations for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...variations.map(x => ({
                                value: x.variation+"  id="+x.id, label: x.variation+"  id="+x.id, name:x.variation, id:x.id
                            }))]}
                            placeholder={'Variations'}
                            components={{
                                Option,
                            }}  />
                </div>
            </div>

        </React.Fragment>
    )
}
export default UploadBulkProductPage